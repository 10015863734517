"use client";
import { User } from "@/app/__generated__/graphql";
import {
  useContext,
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import * as Sentry from "@sentry/nextjs";

const UserContext = createContext<{
  user: User | null;
  setUser: Dispatch<SetStateAction<User>>;
} | null>({ user: null, setUser: null });

export function useUser() {
  return useContext(UserContext);
}

export function useUserPermissions() {
  return useUser()?.user?.permissions;
}

// you need to create a component to wrap your app in
export function UserProvider({
  children,
  user,
}: React.PropsWithChildren<{
  user?: User | null;
} | null>) {
  const [currentUser, setCurrentUser] = useState(user);
  useEffect(() => {
    Sentry.setUser(user ? { id: user?.id } : null);
  }, [user]);
  return (
    <UserContext.Provider
      value={{ user: currentUser, setUser: setCurrentUser }}
    >
      {children}
    </UserContext.Provider>
  );
}
